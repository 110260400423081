<template>
  <div>
    <Newheader></Newheader>
    <WujieVue :key="url" width="100%" height="100%" name="f9s" :url="url" ></WujieVue>
    <div style="height: 60px;background-color:#f5f5f5;"></div>
    <newfooter />
  </div>
</template>

<script>
export default {
  name: 'Vue3F9',

  data() {
    return {
          url:""
    };
  },
  watch:{
    $route(){
      this.url=this.$route.params.url==1?'./gy/f9s.html':'./gy/i_One_XSs.html';
    }
  },
  mounted() {
      this.url=this.$route.params.url==1?'./gy/f9s.html':'./gy/i_One_XSs.html';
  },

  methods: {

  },
};
</script>

<style scoped>

</style>